import { render, staticRenderFns } from "./ProductFlax.vue?vue&type=template&id=20132e08&scoped=true&"
import script from "./ProductFlax.vue?vue&type=script&lang=js&"
export * from "./ProductFlax.vue?vue&type=script&lang=js&"
import style0 from "./ProductFlax.vue?vue&type=style&index=0&id=20132e08&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20132e08",
  null
  
)

export default component.exports