import { render, staticRenderFns } from "./ProductGomimango.vue?vue&type=template&id=8a275478&scoped=true&"
import script from "./ProductGomimango.vue?vue&type=script&lang=js&"
export * from "./ProductGomimango.vue?vue&type=script&lang=js&"
import style0 from "./ProductGomimango.vue?vue&type=style&index=0&id=8a275478&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a275478",
  null
  
)

export default component.exports