<template>
  	<div id="pr_products" class="container-fluid">

        <!-- video main exclusive -->
        <!-- <section id="pr_video_main" class="section__index d-flex">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="video_main" type="video/mp4">
            </video>

            <div id="pr_text_main" style="width: 100%; position: absolute;">
                <div class="row d-flex justify-content-center" style="height: 100%;">
                    <div class="col-12 d-flex justify-content-center p-0" style="height: 100%;">
                        <span class="pr--title d-md-flex my-4 my-sm-5">
                            NUESTROS PRODUCTOS
                        </span>
                    </div>
                    <div class="col-10 col-sm-9 col-md-10 col-xl-12 px-5 text-center">
                        <img :src="section1_img1" class="img-fluid">
                    </div>
                </div>
            </div>
        </section> -->

  		<!-- video main -->
		<section id="pr_video_main" class="section__index d-flex">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="video_main" type="video/mp4">
            </video>
            <div id="overlay_main_video">
                <div class="row text-center d-flex justify-content-center align-items-end" style="height: 100%">
                    <div data-aos="fade-up" class="col-12 d-flex justify-content-center">
                        <span class="pr--title d-none d-sm-flex d-md-flex mb-5">
                            NUESTROS PRODUCTOS
                        </span>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="600" class="col-7 col-md-7 col-lg-8 col-xl-9 text-center">
                        <img :src="section1_img1" class="img-fluid mb-0 mb-xl-1">
                    </div>
                </div>
            </div>
        </section>

        <!-- line green -->
        <section class="row bg-success d-flex justify-content-center align-items-center py-5">
            <div>
                <img :src="img_logo_white" data-aos="fade-up" class="img-fluid mb-4 mb-xl-5"> <br>

                <div data-aos="fade-up" data-aos-delay="400" class="px-5" style="max-width: 900px;">
                    <span class="pr--description text-white">
                        Nuestro objetivo es ofrecer productos de la más alta calidad, a través de procesos de logística y comercialización, para entregar a nuestro consumidor final un producto de excelencia.
                    </span>
                </div>
            </div>
        </section>

        <!-- section products -->
        <section class="row p-5">
            <div class="col-12 d-flex justify-content-center pb-5">
                <div data-aos="fade-up" class="pb-5" style="max-width: 600px;">
                    <span class="pr--title poppins-black">CONOCE NUESTRA LÍNEA DE PRODUCTOS</span>    
                </div>
            </div>

            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6 d-flex">
                        <img :src="section5_img1" class="img-fluid px-5">        
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                FRUTOS SECOS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Conoce nuestra amplia variedad de frutos secos.</span> <br>
                                <span @click="redirect('category_nuts')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6">
                        <img :src="section5_img2" class="img-fluid">        
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                SUPER FOODS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Productos super foods para una mejor alimentación.</span> <br>
                                <span @click="redirect('category_super')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6">
                        <img :src="section5_img3" class="img-fluid px-5">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                FRUTOS DESHIDRATADOS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Calidad y un excelente sabor en nuestros frutos deshidratados.</span> <br>
                                <span @click="redirect('category_dehydrated')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6 px-5">
                        <img :src="section5_img4" class="img-fluid">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                JAMAICA
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Nuestra Jamaica premier de la mejor calidad.</span> <br>
                                <span @click="redirect('product_jamaica')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6 pl-5">
                        <img :src="section5_img5" class="img-fluid px-5">        
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                ENCHILADOS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Los mejores snacks para las mejores ocasiones.</span> <br>
                                <span @click="redirect('category_hot')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6">
                        <img :src="section5_img6" class="img-fluid pl-5">        
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                ESPECIAS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Conoce nuestra gran variedad de especias.</span> <br>
                                <span @click="redirect('category_spices')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- download section -->
        <section data-aos="fade-up" data-aos-delay="600" class="row p-5 d-flex justify-content-center align-items-center">
            <a href="catalogo_de_productos_2020.pdf" download="catalogo_de_productos.pdf">
                <b-button variant="primary" class="py-3 px-4">
                    <span class="pr--description poppins-bold text-white mr-3 align-middle">CATÁLOGO DE PRODUCTOS</span>
                    <img :src="img_download" class="img-fluid" style="max-width: 36px;">
                </b-button>
            </a>
        </section>
  	</div>
</template>

<script>
	// Video
    import video_main from '../assets/video/weare/bg-productos-somos.mp4'
    
    // Images
    import img_logo_white from '../assets/images/home/logo-premier-blanco.png'
    import section1_img1 from '../assets/images/weare/productos-bolsas.png'

    import section5_img1 from '../assets/images/home/productos/foto-pistache.png'
    import section5_img2 from '../assets/images/home/productos/foto-linaza.png'
    import section5_img3 from '../assets/images/home/productos/frutos-deshidratados.png'
    import section5_img4 from '../assets/images/home/productos/foto-jamaica.png'
    import section5_img5 from '../assets/images/home/productos/foto-mango.png'
    import section5_img6 from '../assets/images/home/productos/foto-quinoa.png'

    import img_download from '../assets/images/products/download-icon.png'
    
    
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_products',
		data() {
			return {
                video_main,
                img_logo_white,
                section1_img1,

                section5_img1,
                section5_img2,
                section5_img3,
                section5_img4,
                section5_img5,
                section5_img6,
                img_download
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
        created() {

            $(window).resize(function() {
                let w = 0
                let height_navbar = 0
                
                if($(document).scrollTop() > $('#pr_line_primary').height()) {
                    height_navbar = $('#pr_line_primary').height() + $('#pr_navbar').height()
                }
                else {
                    height_navbar = $('#pr_navbar').height()
                }

                if($('#pr_video_main').width() > 888) {
                    w = ($('#pr_video_main').width() / 10) * 10
                }
                else if($('#pr_video_main').width() > 631) {
                    w = ($('#pr_video_main').width() / 10) * 10
                }
                else {
                    w = ($('#pr_video_main').width() / 10) * 10
                    height_navbar = $('#pr_line_primary').height() + $('#pr_navbar').height() + 10
                }
                
                
                $("#overlay_main_video").css("width", w)
                let position_left = (($('#pr_video_main').width() / 2) - ($("#overlay_main_video").width() / 2))
                $("#overlay_main_video").css("left", position_left)
                let position_top = height_navbar + (($('#pr_video_main').height() / 1.5) - ($("#overlay_main_video").height() / 2))
                $("#overlay_main_video").css("top", position_top)


                // Video main
                // let height_ref = $('#pr_video_main').height()
                // $("#pr_text_main").css("height", height_ref)
            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 2000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_products {
		
	}
</style>