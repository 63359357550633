<template>
  	<div id="pr_self_services" class="container-fluid">
  		<!-- img main -->
        <section id="pr_video_main" class="section__index d-flex">
            <img :src="img_main" class="img-fluid">

            <div id="pr_text_main" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 d-flex justify-content-center align-items-end p-0 pl-lg-5" style="height: 100%;">
                        <div class="p-0 pl-lg-5 pb-3 pb-lg-5" style="max-width: 500px;">
                            <span class="pr--title text-white text-left">
                                AUTOSERVICIOS
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- section group 1 -->
        <section class="row section__index bg_content pb-5">
            <div class="container">
                <div class="row ">
                    <div class="col-12 py-5">
                        <span class="pr--title text-content-black">Encuentra tu <br class="d-none d-md-block"> producto preferido</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-self col-12 col-sm-6 col-md-4 col-lg-3 bg_content d-flex justify-content-center align-items-center my-2 px-2"
                        v-for="(self, index_self) of self_services" :key="index_self">

                            <div class="col-self__container d-flex justify-content-center align-items-center bg-white w-100 py-5 px-1 px-lg-1 px-xl-4">
                                <img :src="self.image" class="img-fluid" style="margin-top: -20px">
                                <span @click="openModal(self.image, index_self)" class="pr--description-sm position-absolute lato-bold text-content-black cursor-pointer" 
                                    style="margin-bottom: -180px;">
                                    VER PRODUCTOS
                                </span>
                            </div>
                    </div>
                </div>
            </div>
        </section>

        <pr-modal 
            :showModal="show_modal" 
            @onClose="handleClose()" 
            bgColor="primary"
            :imageTitle="select_image"
            maxWidth="700">

            <div class="col-12 text-left overflow-auto">
                <div class="my-4">
                    <span class="pr--description lato-bold text-white">PRODUCTOS:</span>    
                </div>

                <ul v-if="element_select !== null">
                    <li class="pr--description-sm lato-regular text-white my-3" v-for="(point, index_point) of self_services[element_select].points" :key="index_point"
                        style="margin-left: -20px;">
                            {{ point }}
                    </li>
                </ul> 

                <div class="mt-5" v-if="element_select !== null && self_services[element_select].coming_soon !== undefined">
                    <span class="pr--description-sm lato-bold text-white">
                        PROXIMAMENTE:
                    </span>
                    <ul>
                        <li class="pr--description-sm lato-regular text-white my-3" v-for="(coming, index_coming) of self_services[element_select].coming_soon" :key="index_coming"
                            style="margin-left: -20px;">
                                {{ coming }}
                        </li>
                    </ul> 
                </div>   
            </div>

        </pr-modal>
  	</div>
</template>

<script>
    // Components 
    import PrModal from '../components/pr-modal.vue'

    // Images
    import img_main from '../assets/images/self_services/foto-autoservicios.jpg'

    import img_ss1 from '../assets/images/self_services/01-city-club.png'
    import img_ss2 from '../assets/images/self_services/02-chedraui.png'
    import img_ss3 from '../assets/images/self_services/03-la-comer.png'
    import img_ss4 from '../assets/images/self_services/04-heb.png'

    import img_ss5 from '../assets/images/self_services/05-liverpool.png'
    import img_ss6 from '../assets/images/self_services/06-oxxo.png'
    import img_ss7 from '../assets/images/self_services/07-sams.png'
    import img_ss8 from '../assets/images/self_services/08-soriana.png'

    import img_ss9 from '../assets/images/self_services/09-dax.png'
    import img_ss10 from '../assets/images/self_services/10-al-super.png'
    import img_ss11 from '../assets/images/self_services/11-smart.png'
    import img_ss12 from '../assets/images/self_services/12-waltmart.png'

    import img_ss13 from '../assets/images/self_services/13-vinoteca.png'

    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_infrastructure',
        components: {
            'pr-modal': PrModal
        },
		data() {
			return {
                img_main, 
                show_modal: false,
                select_image: null,
                element_select: null,

                self_services: [
                    {
                        image: img_ss1,
                        points: [
                            'JAMAICA SUDAN  CAJA 8 BOLSAS DE 1 KG',
                            'NUECES MIXTAS SIN SAL VITROLERO 800 GRS',
                            'NUECES MIXTAS SAL VITROLERO 800 GR',
                            'MEZCLA FRUTOS Y NUECES VITROLERO 900 GRS'
                        ]
                    },
                    {
                        image: img_ss2,
                        points: [
                            'Snack Premier Pina Enchilada 150 G',
                            'MANGO ENCHILADO PREMIER 1 KILO',
                            'ARANDANO TAJIN 120 GRS',
                            'ALMENDRA TOSTADA  150 GR',
                            'ALMENDRA NATURAL 150 GR',
                            'ARANDANO PREMIER  500 GR',
                            'NUEZ INDIA  150 GRS',
                            'PISTACHE TOSTADO 150 GRS',
                            'ARANDANO CRAISINS ENCHILADO 130 GRAMOS',
                            'ARANDANO DESHIDRATADO PREMIER 120',
                            'MANGO ENCHILADO  16  150 GRS',
                            'ARANDANOS OCEAN SPRAY CEREZA 130 GRS',
                            'ARANDANOS OCEAN SPRAY REDUCE SUGAR 130 GRS',
                            'NUECES MIXTAS SIN SAL VITROLERO 800 GRS',
                            'NUECES MIXTAS SAL VITROLERO 800 GRS',
                            'ARANDANOS OCEAN SPRAY CRAISINS ORIGINAL 130 GRS',
                            'MEZCLA FRUTOS Y NUECES VITROLERO 900 GRS',
                            'NUEZ WESTERN 150 GRS'
                        ]
                    },
                    {
                        image: img_ss3,
                        points: [
                            'ARANDANOS OCEAN SPRAY CRAISINS ORIGINAL 130 GRS',
                            'ARANDANO CRAISINS ENCHILADO 130 GRAMOS',
                            'ALMENDRA TOSTADA  36  150 GR',
                            'ARANDANO PREMIER 16  120 GR',
                            'PISTACHE CALIFORNIA  24  500 GR',
                            'ALMENDRA NATURAL  36  150 GR',
                            'PISTACHE TOSTADO 36 150 GRS',
                            'ARANDANOS OCEAN SPRAY CEREZA 130 GRS',
                            'ARANDANOS OCEAN SPRAY REDUCE SUGAR 130 GRS',
                            'MANGO ENCHILADO  36  150 GRS',
                            'NUEZ INDIA 36 150 GR',
                            'ARANDANO PREMIER  12  500 GR',
                            'MEZCLA FRUTOS Y NUECES VITROLERO 900 GRS',
                            'JAMAICA SUDAN  CAJA 20 BOLSAS DE 500 GRS',
                            'VITROLERO MARCA GOLDEN HILLS PISTACJE 1, 130 GRS',
                            'VITROLERO MARCA GOLDEN HILLS NUEZ DE LA INDICA 1,130 GRS.',
                            'VITROLERO MARCA GOLDEN HILLS ALMENDRA NATURAL 1,130 GRS.',
                            'VITROLERO MARCA GOLDEN HILLS MIX DE NUECES PREMIUM 1,130 GRS.',
                            'NUEZ WESTERN  36  150 GRS',
                            'MANGO ENCHILADO  36  150 GR'
                        ]
                    },
                    {
                        image: img_ss4,
                        points: [
                            'ALMENDRA TOSTADA  150 GR',
                            'MANGO ENCHILADO 150 GRS',
                            'ALMENDRA NATURAL  150 GR',
                            'NUEZ INDIA 150 GR'
                        ]
                    },
                    {
                        image: img_ss5,
                        points: [
                            'ARANDANO PREMIER 120 GR',
                            'ALMENDRA NATURAL  150 GR',
                            'ARANDANO TAJIN 120 GRS',
                            'NUECES MIXTAS SAL VITROLERO 800 GRS',
                            'NUEZ INDIA 150 GRS',
                            'PISTACHE TOSTADO 150 GRS',
                            'NUECES MIXTAS SIN SAL VITROLERO 800 GRS',
                            'MEZCLA FRUTOS Y NUECES VITROLERO 900 GRS'
                        ]
                    },
                    {
                        image: img_ss6,
                        points: [
                            'ARANDANOS OCEAN SPRAY  ORIGINAL DESHIDRATADO 35 GRS',
                            'ARANDANOS OCEAN SPRAY DESHIDRATADO ENCHILADO 35 GRS'
                        ]
                    },
                    {
                        image: img_ss7,
                        points: [
                            'MANGO ENCHILADO PREMIER 1 KILO'
                        ]
                    },
                    {
                        image: img_ss8,
                        points: [
                            'ARANDANO TAJIN 120 GRS',
                            'ALMENDRA NATURAL  36  150 GR',
                            'ARANDANO PREMIER  12  500 GR',
                            'PISTACHE TOSTADO 36 150 GRS',
                            'VITROLERO ARANDANOS CUB CHOCOLATE VALLEY FOODS 1KG',
                            'NUEZ INDIA 36 150 GR',
                            'ARANDANO CRAISINS ENCHILADO 130 GRAMOS',
                            'VITROLERO NUECES MIXTAS CON SAL VALLEY FOODS 800GR',
                            'MANGO ENCHILADO  36  150 GRS',
                            'VITROLERO FRUTOS Y NUECES VALLEY FOODS 900 GR',
                            'VITROLERO NUECES MIXTAS SIN SAL VALLEY FOODS 800 GRS',
                            'ARANDANOS OCEAN SPRAY CRAISINS ORIGINAL  130 GRS',
                            'ARANDANO PREMIER 16  120 GR',
                            'GOMIMANGO ENCHILADO  12 500 GRS',
                            'ALMENDRA TOSTADA  6  227 GR'
                        ]
                    },
                    {
                        image: img_ss9,
                        points: [
                            'PISTACHE TOSTADO  150 GR',
                            'NUEZ INDIA  150 GR',
                            'ALMENDRA NATURAL BOLSA DE 150 GRAMOS',
                            'NUEZ WESTERN DE 150 GRS',
                            'MANGO ENCHILADO CAJA CON 16 BOLSAS DE 150 GRAMOS',
                            'ARANDANO CRAISINS ENCHILADO 130 GRAMOS',
                            'ARANDANOS OCEAN SPRAY CRAISINS ORIGINAL  130 GRS',
                            'ARANDANOS OCEAN SPRAY CEREZA 130 GRS',
                            'ARANDANOS OCEAN SPRAY REDUCE SUGAR 130 GRS'
                        ]
                    },
                    {
                        image: img_ss10,
                        points: [
                            'PISTACHE TOSTADO  150 GR',
                            'NUECES MIXTAS SIN SAL VITROLEROS DE 800 GRAMOS',
                            'NUECES MIXTAS CON SAL VITROLEROS DE 800 GRAMOS',
                            'ALMENDRA TOSTADA  150 GR',
                            'ALMENDRA NATURAL BOLSAS DE 150 GRAMOS',
                            'ARANDANO CRAISINS ENCHILADO 130 GRAMOS',
                            'ARANDANOS OCEAN SPRAY CRAISINS ORIGINAL  130 GRS',
                            'ARANDANOS OCEAN SPRAY CEREZA 130 GRS',
                            'ARANDANOS OCEAN SPRAY REDUCE SUGAR 130 GRS'
                        ]
                    },
                    {
                        image: img_ss11,
                        points: [
                            'ALMENDRA NATURAL BOLSAS DE 150 GRAMOS',
                            'NUEZ INDIA  150 GR',
                            'PISTACHE TOSTADO 150 GR',
                            'MANGO ENCHILADO BOLSAS DE 150 GRAMOS',
                            'ALMENDRA TOSTADA  150 GR',
                            'ARANDANO PREMIER TAJIN 120 GRS',
                            'ARANDANO PREMIER  500 GR'
                        ]
                    },
                    {
                        image: img_ss12,
                        points: [
                            'ARANDANO CRAISINS ENCHILADO 130 GRAMOS',
                            'ARANDANOS OCEAN SPRAY CRAISINS ORIGINAL  130 GRS',
                            'ARANDANOS OCEAN SPRAY CEREZA 130 GRS',
                            'ARANDANOS OCEAN SPRAY REDUCE SUGAR 130 GRS'
                        ],
                        coming_soon: [
                            'ARANDANO TAJIN 120 GRS'
                        ]
                    },
                    {
                        image: img_ss13,
                        points: [
                            'PISTACHE TOSTADO  150 GR',
                            'NUEZ WESTERN DE 150 GRS',
                            'NUEZ INDIA  150 GR',
                            'MANGO ENCHILADO BOLSAS DE 150 GRAMOS',
                            'ALMENDRA NATURAL BOLSAS DE 150 GRAMOS',
                            'ALMENDRA TOSTADA  150 GR',
                            'ARANDANO PREMIER 120 GRS',
                            'ARANDANO PREMIER TAJIN 120 GRS'
                        ]
                    },
                ]
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            },
            openModal(image = null, index) {
                this.show_modal = true
                this.select_image = image
                this.element_select = index
            },
            handleClose() {
                this.show_modal = false
            }
        },
        created() {
            $(window).resize(function() {
                // Image main
                let height_ref = $('#pr_video_main').height()
                $("#pr_text_main").css("height", height_ref)

                // Col same height
                var height = $('.col-self:visible').css('height')
                let arr = $('.col-self')
                arr.css("height", height)

                var height2 = $('.col-self__container:visible').css('height')
                let arr2 = $('.col-self__container')
                arr2.css("height", height2)
            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 2000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_self_services {
        .bg_content {
            background-color: #f5f5f5;
        }
	}
</style>