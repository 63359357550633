<template>
  	<div id="pr_contact" class="container-fluid bg-light">
  		<!-- video main -->
		<section id="pr_video_main" class="section__index d-flex">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="video_main" type="video/mp4">
            </video>
            <div id="overlay_main_video">
                <div class="overlay_main_video--text mb-2 mb-sm-5 px-0 px-md-2 px-lg-4">
                    <div class="px-2 px-md-3 px-lg-4">
                        <span class="pr--title-lg poppins-bold text-white">CONTACTO</span> <br>
                    </div>
                </div>
            </div>
        </section>

        <!-- section form -->
        <section class="row py-5">
            <div class="col-12 col-lg-5 col-xl-6 d-flex justify-content-center text-left mb-4">
                <div style="max-width: 500px;">
                    <span class="pr--title">EN GRUPO PREMIER QUEREMOS ESCUCHAR DE TI</span> <br>

                    <span class="pr-description">
                        Si tienes alguna duda o comentario comunícate con nosotros y 
                        en breve uno de nuestros compañeros se pondrá en contacto contigo. <br>
                        Envíanos un correo electrónico o completa el siguiente formulario.
                    </span>
                </div>
            </div>
            <div class="col-12 col-lg-7 col-xl-6 d-flex justify-content-center justify-content-lg-start mb-4">
                <div class="bg-white p-5" style="background-color: white; max-width: 800px;">

                    <div class="row" v-if="!sended_email">
                        <div class="col-12 col-lg-6 mb-4">
                            <b-form-input v-model="form.name" placeholder="Nombre"></b-form-input>        
                        </div>
                        <div class="col-12 col-lg-6 mb-4">
                            <b-form-input v-model="form.email" placeholder="Correo"></b-form-input>
                        </div>
                        <div class="col-12 col-lg-6 mb-4">
                            <b-form-input v-model="form.phone" placeholder="Teléfono"></b-form-input>        
                        </div>
                        <div class="col-12 col-lg-6 mb-4">
                            <b-form-input v-model="form.city" placeholder="Ciudad"></b-form-input>        
                        </div>
                        <div class="col-12 mb-4">
                            <b-form-textarea id="textarea" v-model="form.message" placeholder="Mensaje" rows="3" max-rows="6"></b-form-textarea>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <b-button variant="success" class="text-white py-2 px-4">ENVIAR MENSAJE</b-button>
                        </div>
                    </div>

                    <div class="row" v-else>
                        <div class="col-12">
                            <span class="pr--description poppins-bold">¡Gracias!</span> <br><br>
                            <span class="pr--description poppins-single">Nos contactaremos contigo a la brevedad.</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>

        <!-- section branchs -->
        <section class="row d-flex justify-content-center pb-5" v-if="false">
            <div class="col-10">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 col-lg-6 d-flex justify-content-center text-left my-5">
                        <div style="max-width: 600px">
                            <img :src="section1_img1" class="img-fluid">
                            <div class="mt-3 mb-1 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">GUADALAJARA</span>    
                            </div>
                            <div class="ml-4 ml-lg-5 px-4">
                                <span class="pr--description">
                                    Paseo del Pacífico 645 Carretera <br>
                                    Guadalajara – Nogales km 13.5 <br>
                                    Technology Park Guadalajara Zapopan, <br>
                                    Jalisco, México. C.P. 45222 <br>
                                    Teléfono: + 52 (33) 8000 8300
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 d-flex justify-content-center text-left my-5">
                        <div style="max-width: 600px">
                            <img :src="section1_img2" class="img-fluid">
                            <div class="mt-3 mb-1 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">CIUDAD DE MÉXICO</span>    
                            </div>
                            <div class="ml-4 ml-lg-5 px-4">
                                <span class="pr--description">
                                    Calle 2 Bodega #17 A Central de Abastos <br>
                                    Iztapalapa, D.F. <br>
                                    Teléfono: +52 (55) 5600 7192
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 d-flex justify-content-center text-left my-5">
                        <div style="max-width: 600px">
                            <img :src="section1_img3" class="img-fluid">
                            <div class="mt-3 mb-1 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">MONTERREY</span>    
                            </div>
                            <div class="ml-4 ml-lg-5 px-4">
                                <span class="pr--description">
                                    Av. Ignacio Sepúlveda S/N Parque Industrial <br>
                                    Kalos Encarnación, Bodega 140 Apodaca <br>
                                    NL C.P. 66630 <br>
                                    Celular: 81 1204 2053 <br>
                                    E-mail: ventasc7@gpopremier.com
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 d-flex justify-content-center text-left my-5">
                        <div style="max-width: 600px">
                            <img :src="section1_img4" class="img-fluid">
                            <div class="mt-3 mb-2 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">TLAJOMULCO</span><br>
                                <div style="margin-top: -10px">
                                    <span class="pr--description-sm poppins-bold px-5">PLANTA PROCESADORA</span>    
                                </div>
                            </div>
                            <div class="ml-4 ml-lg-5 px-4">
                                <span class="pr--description">
                                    Camino Antiguo a San Isidro #910 <br>
                                    Col. Nicolás R. Casillas <br>
                                    C.P. 45645 <br>
                                    Teléfono: 36861438 <br>
                                    E-mail: hri@gpopremier.com
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 d-flex justify-content-center text-left my-5">
                        <div style="max-width: 600px">
                            <img :src="section1_img5" class="img-fluid">
                            <div class="mt-3 mb-2 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">GUADALAJARA </span><br>
                                <div style="margin-top: -10px">
                                    <span class="pr--description-sm poppins-bold px-5">PUNTO DE VENTA</span>    
                                </div>
                            </div>
                            <div class="ml-4 ml-lg-5 px-4">
                                <span class="pr--description">
                                    Mercado de Abastos, Calle 7 <br>
                                    Bloque G # 701, Mercado de Abastos <br>
                                    Guadalajara, Jal. Mex. <br>
                                    Teléfono: (33) 8000 8300 <br>
                                    E-mail: Irr@gpopremier.com
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  	</div>
</template>

<script>
	// Video
    import video_main from '../assets/video/contact/loop-contacto.mp4'
    
    // Images
    import img_0 from '../assets/images/icono-point.png'
    import section1_img1 from '../assets/images/contact/foto-guadalajara.png'
    import section1_img2 from '../assets/images/contact/foto-ciudad-de-mexico.png'
    import section1_img3 from '../assets/images/contact/foto-monterrey.jpg'
    import section1_img4 from '../assets/images/contact/foto-tlajomulco-planta-procesadora.png'
    import section1_img5 from '../assets/images/contact/foto-guadalajara-punto-de-venta.png'
    
    
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_contact',
		data() {
			return {
                video_main,
                img_0,
                section1_img1,
                section1_img2,
                section1_img3,
                section1_img4,
                section1_img5,

                form: {
                    name: null,
                    phone: null,
                    email: null,
                    city: null,
                    message: null
                },
                sended_email: false
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            },

            sendMail() {
                fetch('https://grupo-premier.mx/send_email.php', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, /',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        name: this.form.name, 
                        phone: this.form.phone, 
                        email: this.form.email,
                        city: this.form.city,
                        message: this.form.message 
                    })
                }).then(
                    res => res.json()
                ).then(
                    res => console.log(res)
                )

                this.sended_email = true;

                $(document).ready(function() {
                    $("body").children().each(function() {
                        $(this).html($(this).html().replace(/&#8232;/g," "))
                    })
                })
            }
        },
        created() {
            $(window).resize(function() {
                let w = 0
                if($('#pr_video_main').width() > 888) {
                    w = ($('#pr_video_main').width() / 10) * 3
                }
                else {
                    w = ($('#pr_video_main').width() / 10) * 5
                }
                
                $("#overlay_main_video").css("width", w)
                let position_left = (($('#pr_video_main').width() / 2) - ($('#overlay_main_video').width() / 2))
                $("#overlay_main_video").css("left", position_left)
                let position_top = (($('#pr_video_main').height() / 3) * 2)
                $("#overlay_main_video").css("top", position_top)
            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_contact {
		min-height: 1000px !important;

        .img_black_white {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
        }

        .img_black_white:hover {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
        }
	}
</style>