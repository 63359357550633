<template>
  	<div id="pr_weare" class="container-fluid">
  		<!-- video main -->
		<section id="pr_video_main" class="section__index d-flex">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="video_main" type="video/mp4">
            </video>

            <div id="pr_text_main" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 mt-5"></div>
                    <div id="we-are" class="col-12 d-flex justify-content-start align-items-center p-0 pl-lg-5" style="height: 100%;">
                        <div data-aos="fade-up" class="text-left p-0 pl-lg-5 pb-3 pb-lg-5">
                            <div class="text-left p-0 pl-lg-5 pb-3 pb-lg-5" style="max-width: 700px;">
                                <span class="pr--title-lg poppins-bold text-white text-left">
                                    SOMOS PREMIER
                                </span> <br>
                                <span class="pr--description text-white">
                                    En distribución de nueces y frutos secos.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div id="overlay_main_video">
                <div class="d-flex justify-content-center align-items-center px-0 px-md-2 px-lg-4">
                    <div class="px-2 px-md-3 px-lg-4">
                        <span class="pr--title-lg poppins-bold text-white"></span> <br>
                        <span class="pr--description d-none d-md-flex text-white"></span>
                    </div>
                </div>
            </div> -->
        </section>

        <!-- line green -->
        <section class="row bg-success d-flex justify-content-center align-items-center py-5">
            <div>
                <img :src="img_logo_white" data-aos="fade-up" class="img-fluid mb-4 mb-xl-5"> <br>

                <div data-aos="fade-up" data-aos-delay="400" class="px-5" style="max-width: 800px;">
                    <span class="pr--description text-white">
                        Somos una empresa dedicada a la comercialización de frutos secos, nueces, almendras, cereales y semillas desde más de 25 países del mundo.
                    </span>
                </div>
            </div>
        </section>

        <!-- cols informatives -->
        <section class="row d-flex justify-content-center align-items-start py-5">
            <div class="col-12 col-md-6 col-lg-4 px-5 px-lg-5 mb-4">
                <img :src="section1_img1" data-aos="fade-up" class="img-fluid">

                <div class="d-flex justify-content-center text-left pt-3 pl-1 pl-lg-3">
                    <div style="max-width: 420px;">
                        <div k>
                            <span class="pr--description poppins-bold">NOSOTROS</span>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" class="pt-3">
                            <span class="pr--description">
                                Nuestra misión es brindar satisfacción total a nuestros clientes, al crear las condiciones 
                                óptimas para el procesamiento y la comercialización de productos alimenticios de calidad.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-5 px-lg-5 mb-4">
                <img :src="section1_img2" data-aos="fade-up" class="img-fluid">

                <div class="d-flex justify-content-center text-left pt-3 px-1 px-lg-3">
                    <div style="max-width: 420px;">
                        <div data-aos="fade-up" data-aos-delay="400">
                            <span class="pr--description poppins-bold">COMPROMISO</span>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" class="pt-3">
                            <span class="pr--description">
                                Satisfacer las necesidades de los mercados nacionales e internacionales con estrategias de 
                                mejora continua que nos permitan brindar las mejores opciones en precio, calidad y servicio.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-5 px-lg-5 mb-4">
                <img :src="section1_img3" data-aos="fade-up" class="img-fluid">

                <div class="d-flex justify-content-center text-left pt-3 px-1 px-lg-3">
                    <div style="max-width: 420px;">
                        <div data-aos="fade-up" data-aos-delay="400">
                            <span class="pr--description poppins-bold">SOCIOS</span>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" class="pt-3">
                            <span class="pr--description">
                                Contamos con una sólida y estrecha relación con nuestros socios alrededor del mundo, la cual 
                                se ha vuelto una pieza fundamental para el desarrollo de nuestra empresa.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- image section -->
        <section id="our-products" class="row">
        	<div class="col-12 d-flex justify-content-center align-items-center p-0">
                <img :src="section1_img4" class="img-fluid">

                <div style="width: 100%; position: absolute;">
                    <div class="row">
                        <div class="col-6 d-flex justify-content-center">
                            <div class="pl-5" style="max-width: 555px;">
                                <center data-aos="fade-up">
                                    <div style="max-width: 400px;">
                                        <img :src="gif_premier_mixed" class="img-fluid mb-4 mb-xl-5"> <br>
                                    </div>    
                                </center>
                                <span data-aos="fade-up" data-aos-delay="400" class="pr--title text-white text-left d-none d-md-flex">
                                    MÁS DE 50 AÑOS HACIENDO HISTORIA
                                </span>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </div>
                </div>
        	</div>
        </section>

        <!-- video products section -->
        <section id="our-products" class="row pb-5" v-if="false">
            <div class="col-12 d-flex justify-content-start align-items-end p-0">
                <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="video-fluid">
                    <source :src="s3_video1" type="video/mp4">
                </video>

                <div class="p-3 p-md-3" style="position: absolute;">
                    <div class="row text-center d-flex justify-content-center align-items-center">
                        <div class="col-12 d-flex justify-content-center">
                            <span class="pr--title d-none d-sm-block d-md-flex mb-5 mb-sm-2">
                                NUESTROS PRODUCTOS
                            </span>
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <span class="pr--description d-none d-md-flex mb-3">
                                Conoce nuestra amplia variedad de productos
                            </span>
                        </div>
                        <div class="col-12 text-center pb-3 pb-sm-4">
                            <b-button @click="redirect('products')" variant="primary" class="poppins-bold py-2 px-4">VER PRODUCTOS</b-button>
                        </div>
                        <div class="col-7 col-md-7 col-lg-8 col-xl-9 text-center">
                            <img :src="section2_img2" class="img-fluid mb-0 mb-xl-1">
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <!-- col image informative -->
        <section class="row">
        	<div class="col-12 col-lg-5 d-flex justify-content-center align-items-center p-2">
                <img :src="section2_img1" data-aos="zoom-in-down" class="img-fluid">
        	</div>
        	<div class="col-12 col-lg-7 pb-5">
        		<div class="row text-left px-0 px-md-5">
        			<div data-aos="fade-up" class="col-12 pt-5">
        				<div style="max-width: 600px;">
		        			<span class="pr--title poppins-bold">PRODUCTOS 100% <span class="text-success">CONFIABLES</span></span>	
		        		</div>
        			</div>
        			<div data-aos="fade-up" data-aos-delay="400" class="col-12 d-flex justify-content-start align-items-center text-left pt-4 pt-xl-5">
                        <div style="max-width: 600px;">
                            <span class="pr--description">
                                En Grupo Premier cuidamos la capacidad en cada uno de nuestros procesos para obtener un producto con la más alta calidad.
                            </span>
                        </div>
        			</div>
        		</div>
        	</div>
        </section>

        <!-- square blue -->
        <section id="our-history" class="row d-flex justify-content-center align-items-center">
            <img :src="section3_img0" class="img-fluid">

            <div class="bg-primary py-4 px-5 img0-s3">
                <img :src="img_logo" data-aos="fade-up" class="img-fluid mb-4 mb-xl-5"> <br>

                <div data-aos="fade-up" data-aos-delay="400" class="mb-4" style="max-width: 900px;">
                    <span class="pr--title text-white">
                        BIENVENIDOS A NUESTRA HISTORIA
                    </span>
                </div>
                <div data-aos="fade-up" data-aos-delay="500" class="d-flex justify-content-center align-items-center">
                    <div style="max-width: 800px;">
                        <span class="pr--description text-white">
                            En los últimos 50 años Grupo Premier nos hemos consolidado como la empresa líder en importación, procesamiento y distribución de frutos secos en México.
                        </span>    
                    </div>
                </div>
            </div>
        </section>

        <!-- informative -->
        <section class="row d-flex justify-content-center align-items-start pt-5">
            <div class="col-12 col-sm-10 px-0">
                <div class="row">
                    <div class="col-12 col-lg-6 px-0 px-lg-5 mb-5">
                        <img :src="section3_img1" data-aos="fade-right" class="img-fluid">

                        <div data-aos="fade-up" data-aos-delay="400" class="d-flex justify-content-center text-left pt-3 pl-1 pl-lg-3">
                            <div class="px-2" style="max-width: 671px;">
                                <span class="pr--description">
                                    Grupo Premier nace en 1970 en la ciudad de Guadalajara, Jalisco, con el ideal de satisfacer 
                                    las necesidades de la población en el creciente mercado de alimentos a granel.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 px-0 px-lg-5 mb-5">
                        <img :src="section3_img2" data-aos="fade-left" class="img-fluid">

                        <div data-aos="fade-up" data-aos-delay="400" class="d-flex justify-content-center text-left pt-3 px-1 px-lg-3">
                            <div class="px-2" style="max-width: 671px;">
                                <span class="pr--description">
                                    A través de los años, nuestra empresa se ha consolidado como líder y referente en México como 
                                    proveedor de pistaches, nueces, almendras, cereales, especies y frutos secos.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- informative 2 -->
        <section class="row">
            <div class="col-12 d-flex justify-content-center align-items-center p-0">
                <img :src="section3_img3" data-aos="fade-up" class="img-fluid">

                <div data-aos="fade-up" data-aos-delay="500" style="height: 100%; width: 100%; position: absolute;">
                    <div class="row px-0" style="height: 100%">
                        <div class="col-8 d-flex justify-content-center align-items-end p-4" style="height: 100%">
                            <div class="px-5" style="max-width: 700px;">
                                <span class="pr--description text-white text-left d-none d-sm-flex">
                                    Actualmente contamos con nuestro Corporativo Matriz y Planta Procesadora en Guadalajara, Corporativo en CDMX y una Bodega en Monterrey.
                                </span>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </div>
                </div>
            </div>
        </section>

        <!-- informative 3 -->
        <section class="row d-flex justify-content-center align-items-start py-5">
            <div class="col-12 col-xl-8 px-0">
                <div class="row">
                    <div class="col-12 col-lg-5 order-1 order-lg-0 px-1 px-sm-5 px-xl-5">
                        <div data-aos="fade-up" data-aos-delay="400" class="d-flex justify-content-center align-items-center text-left pt-3 pt-xl-5 px-1 px-lg-3">
                            <div class="px-2" style="max-width: 671px;">
                                <span class="pr--description">
                                    En el año 2018 se realizó la apertura al público de nuestra primera tienda 
                                    de menudeo en el Mercado de Abastos de Guadalajara.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="col-12 col-lg-7 order-0 order-lg-1 px-2 px-sm-5 px-xl-2">
                        <img :src="section3_img4" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>

        <!-- section branchs -->
        <section class="row d-flex justify-content-center py-5" v-if="false">
            <div class="col-10">
                <div class="mb-5">
                    <span class="pr--title">NUESTRAS <span class="text-success">SUCURSALES</span></span>    
                </div>
                

                <div class="row d-flex justify-content-center">
                    <div class="col-12 col-lg-4 d-flex justify-content-center text-left my-4">
                        <div style="max-width: 600px">
                            <img :src="section4_img1" class="img-fluid">
                            <div class="mt-3 mb-1 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">GUADALAJARA</span>    
                            </div>
                            <div class="ml-3">
                                <span class="pr--description">
                                    Paseo del Pacífico 645 Carretera <br>
                                    Guadalajara – Nogales km 13.5 <br>
                                    Technology Park Guadalajara Zapopan, <br>
                                    Jalisco, México. C.P. 45222 <br>
                                    Teléfono: + 52 (33) 8000 8300
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-center text-left my-4">
                        <div style="max-width: 600px">
                            <img :src="section4_img2" class="img-fluid">
                            <div class="mt-3 mb-1 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">CIUDAD DE MÉXICO</span>    
                            </div>
                            <div class="ml-3">
                                <span class="pr--description">
                                    Calle 2 Bodega #17 A Central de Abastos <br>
                                    Iztapalapa, D.F. <br>
                                    Teléfono: +52 (55) 5600 7192
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-center text-left my-4">
                        <div style="max-width: 600px">
                            <img :src="section4_img3" class="img-fluid">
                            <div class="mt-3 mb-1 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">MONTERREY</span>    
                            </div>
                            <div class="ml-3">
                                <span class="pr--description">
                                    Av. Ignacio Sepúlveda S/N Parque Industrial <br>
                                    Kalos Encarnación, Bodega 140 Apodaca <br>
                                    NL C.P. 66630 <br>
                                    Celular: 81 1204 2053 <br>
                                    E-mail: ventasc7@gpopremier.com
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-center text-left my-4">
                        <div style="max-width: 600px">
                            <img :src="section4_img4" class="img-fluid">
                            <div class="mt-3 mb-2 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">GUADALAJARA </span><br>
                                <div style="margin-top: -10px">
                                    <span class="pr--description-sm poppins-bold px-5">PUNTO DE VENTA</span>    
                                </div>
                            </div>
                            <div class="ml-3">
                                <span class="pr--description">
                                    Mercado de Abastos, Calle 7 <br>
                                    Bloque G # 701, Mercado de Abastos <br>
                                    Guadalajara, Jal. Mex. <br>
                                    Teléfono: (33) 8000 8300 <br>
                                    E-mail: Irr@gpopremier.com
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-center text-left my-4">
                        <div style="max-width: 600px">
                            <img :src="section4_img5" class="img-fluid">
                            <div class="mt-3 mb-2 px-4">
                                <img :src="img_0" class="img-fluid mr-3">
                                <span class="pr--description poppins-bold">TLAJOMULCO</span><br>
                                <div style="margin-top: -10px">
                                    <span class="pr--description-sm poppins-bold px-5">PLANTA PROCESADORA</span>    
                                </div>
                            </div>
                            <div class="ml-3">
                                <span class="pr--description">
                                    Camino Antiguo a San Isidro #910 <br>
                                    Col. Nicolás R. Casillas <br>
                                    C.P. 45645 <br>
                                    Teléfono: 36861438 <br>
                                    E-mail: hri@gpopremier.com
                                </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>

        <!-- section ages -->
        <section class="row mb-5">
            <div class="col-12 col-lg-8 col-xl-7 bg-primary">
                <div class="row d-flex justify-content-end my-3">
                    <div id="col_age_1" class="col-2 col-md-2 d-flex justify-content-center align-items-center all_transition_slow">
                        <button id="btn_age_1" @click="changeAge(1)" class="btn btn-link pr--title poppins-bold text-white a_none_dec all_transition_slow">
                            1970
                        </button>
                    </div>
                    <div id="col_age_2" class="col-2 col-md-1 d-flex justify-content-center align-items-center all_transition_slow">
                        <button id="btn_age_2" @click="changeAge(2)" class="btn btn-link pr--description poppins-bold text-muted a_none_dec all_transition_slow">
                            1993
                        </button>
                    </div>
                    <div id="col_age_3" class="col-2 col-md-1 d-flex justify-content-center align-items-center all_transition_slow">
                        <button id="btn_age_3" @click="changeAge(3)" class="btn btn-link pr--description poppins-bold text-muted a_none_dec all_transition_slow">
                            2010
                        </button>
                    </div>
                    <div id="col_age_4" class="col-2 col-md-1 d-flex justify-content-center align-items-center all_transition_slow">
                        <button id="btn_age_4" @click="changeAge(4)" class="btn btn-link pr--description poppins-bold text-muted a_none_dec all_transition_slow">
                            2012
                        </button>
                    </div>
                    <div id="col_age_5" class="col-2 col-md-1 d-flex justify-content-center align-items-center all_transition_slow">
                        <button id="btn_age_5" @click="changeAge(5)" class="btn btn-link pr--description poppins-bold text-muted a_none_dec all_transition_slow">
                            2018
                        </button>
                    </div>
                     <div id="col_age_6" class="col-2 col-md-1 d-flex justify-content-center align-items-center all_transition_slow">
                        <button id="btn_age_6" @click="changeAge(6)" class="btn btn-link pr--description poppins-bold text-muted a_none_dec all_transition_slow">
                            2023
                        </button>
                    </div>
                    <div class="col-1">
                        
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 d-flex justify-content-end text-left">
                        <div class="d-flex justify-content-start my-5" style="max-width: 580px;">
                            <div data-aos="fade-up" data-aos-delay="400" class="d-inline mb-5" style="max-width: 400px">
                                <span class="pr--description text-white" v-show="current_index_age == 1">
                                    En 1970 lo que hoy en día es Grupo Premier, fue originalmente Almacenes Valencia. Ubicado en el Mercado de Abastos de Guadalajara. <br><br>
                                    Su deseo de satisfacer las necesidades de la población en el creciente mercado de alimentos se hizo realidad.
                                </span>

                                <span class="pr--description text-white" v-show="current_index_age == 2">
                                    En 1993 se inaugura la planta procesadora lo que representa un gran paso en nuestra estrategia de expansión, más cerca de obtener el liderazgo que logrará Grupo Premier en los años posteriores. <br><br>
                                    En este año tuvimos una hazaña muy importante ya que por primera vez lanzamos al mercado productos con nuestra marca propia.
                                </span>

                                <span class="pr--description text-white" v-show="current_index_age == 3">
                                    En el 2010 se consolida la fusión entre Almacenes Valencia y Premier.<br><br>
                                    Creando así GRUPO PREMIER. Se construye el avanzado edificio corporativo en el Technology Park para albergar las oficinas centrales del Grupo. Obra que fue galardonada con el premio nacional de arquitectura CEMEX como 1er lugar en desarrollo de obra industrial.
                                </span>

                                <span class="pr--description text-white" v-show="current_index_age == 4">
                                    En el 2012 se realiza una evolución del logotipo, integrando un fondo de azul degradado, otorgándole mayor solidez a la marca.<br><br>
                                    En este año se amplía el edificio corporativo en el Technology Park para albergar las oficinas centrales del Grupo. Se cierran tratos con más proveedores a nivel mundial, aumentando así nuestra linea de productos.
                                </span>

                                <span class="pr--description text-white" v-show="current_index_age == 5">
                                    En abril del 2018 se inauguró el corporativo en ciudad de México.<br><br>
                                    En septiembre se aperturó al público nuestra primer tienda con mostrador mayorista y minorista en el mercado de abastos de Guadalajara, Mercado Premier. En Octubre inicia actividades Corporativo Monterrey.
                                </span>

                                <span class="pr--description text-white" v-show="current_index_age == 6">
                                    Actualmente en Grupo Premier importamos frutos secos de Canadá, Estados Unidos, México, Brasil, Perú, Chile, Argentina, Nigeria, Sudán, Egipto, India, Tailandia y China. En un ambiente adecuado de inocuidad, con maquinaria de última tecnología y bajo una meticulosa supervisión para conservar una excelencia calidad en nuestros productos terminados. Hemos conseguido la satisfacción de nuestros consumidores de todo el país.<br><br>
                                    
                                    <ul>
                                        <li>Apertura Corporativo CDMX</li>
                                        <li>Apertura Mercado Premier</li>
                                        <li>Apertura de Premier Nuts</li>
                                        <li>Lanzamiento FrutiCoso</li>
                                    </ul>

                                    <a href="http://premiernuts.mx/" target="_blank">
                                        <img :src="img_nuts" class="img-fluid m-2">
                                    </a>
                                    <a href="https://mercadopremier.com/" target="_blank">
                                        <img :src="img_mercado" class="img-fluid m-2">
                                    </a>
                                </span>

                            </div>
                            <div class="d-none d-md-inline" style="width: 180px"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-5 bg-light d-flex justify-content-center justify-content-lg-start align-items-center">
                <div id="div_img_logo" class="d-flex justify-content-center align-items-center mb-5">
                    <img 
                        :src="
                            (current_index_age == 1) ? logo_1970 : 
                            (current_index_age == 2) ? logo_1993 : 
                            (current_index_age == 3) ? logo_2010 : 
                            (current_index_age == 4) ? logo_2012 : 
                            (current_index_age == 5) ? logo_2018 : 
                            logo_2021 " 
                        class="img-fluid" :class="(current_index_age == 2) ? 'px-4' : ''">    
                </div>
            </div>
        </section>

        <!-- section family -->
        <section class="row d-flex justify-content-center pt-5 pb-4" v-if="false">
            <div class="col-12 px-0">
                <div class="mb-5">
                    <span class="pr--title">NUESTRA <span class="text-success">FAMILIA</span></span>
                </div>

                <div class="row d-flex justify-content-center align-items-center p-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center my-3">
                        <img :src="section5_img1" class="img-fluid">
                        <div style="width: 100%; position: absolute;">
                            <div class="row">
                                <div class="col-12">
                                    <center>
                                        <div style="max-width: 240px;">
                                            <img :src="section5_img1_5" class="img-fluid">
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center my-3">
                        <img :src="section5_img2" class="img-fluid">
                        <div style="width: 100%; position: absolute;">
                            <div class="row">
                                <div class="col-12">
                                    <center>
                                        <div class="mb-3">
                                            <span class="pr--description poppins-regular text-white">PUNTO DE VENTA</span>    
                                        </div>
                                        
                                        <div style="max-width: 250px;">
                                            <img :src="section5_img2_5" class="img-fluid">
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center my-3">
                        <img :src="section5_img3" class="img-fluid">
                        <div style="width: 100%; position: absolute;">
                            <div class="row">
                                <div class="col-12">
                                    <center>
                                        <div style="max-width: 250px;">
                                            <img :src="section5_img3_5" class="img-fluid">
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row d-flex justify-content-center align-items-center px-0 py-4">
                    <div class="col-4 col-lg-2 d-flex justify-content-center align-items-center my-3">
                        <img :src="img_customer1" class="img-fluid">
                    </div>
                    <div class="col-4 col-lg-2 d-flex justify-content-center align-items-center my-3">
                        <img :src="img_customer2" class="img-fluid">
                    </div>
                    <div class="col-4 col-lg-2 d-flex justify-content-center align-items-center my-3">
                        <img :src="img_customer3" class="img-fluid">
                    </div>
                    <div class="col-4 col-lg-2 d-flex justify-content-center align-items-center my-3">
                        <img :src="img_customer4" class="img-fluid">
                    </div>
                    <div class="col-4 col-lg-2 d-flex justify-content-center align-items-center my-3">
                        <img :src="img_customer5" class="img-fluid">
                    </div>
                    <div class="col-4 col-lg-2 d-flex justify-content-center align-items-center my-3">
                        <img :src="img_customer6" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
  	</div>
</template>

<script>
	// Video
    import video_main from '../assets/video/weare/loop-somos.mp4'
    import s3_video1 from '../assets/video/weare/bg-productos-somos.mp4'
    
    // Images
    import img_logo_white from '../assets/images/home/logo-premier-blanco.png'
    import section1_img1 from '../assets/images/weare/foto-nosotros.png'
    import section1_img2 from '../assets/images/weare/foto-compromiso.png'
    import section1_img3 from '../assets/images/weare/foto-socios.png'
    import section1_img4 from '../assets/images/weare/foto-50ages.png'

    import section2_img1 from '../assets/images/weare/foto-nuez-india.png'
    import section2_img2 from '../assets/images/weare/productos-bolsas.png'
    import section2_img3 from '../assets/images/home/foto-nueces.png'
    import img_logo from '../assets/images/home/logo-premier.png'

    import section3_img0 from '../assets/images/weare/foto-premium.png'
    import section3_img1 from '../assets/images/weare/foto-bodega.png'
    import section3_img2 from '../assets/images/weare/foto-pared-platos.png'
    import section3_img3 from '../assets/images/weare/foto-entrada-corporativo.png'
    import section3_img4 from '../assets/images/weare/foto-tienda.png'

    import img_0 from '../assets/images/icono-point.png'
    import section4_img1 from '../assets/images/weare/foto-guadalajara-bodega.png'
    import section4_img2 from '../assets/images/weare/foto-cuidad-de-mexico.png'
    import section4_img3 from '../assets/images/weare/foto-monterrey.jpg'
    import section4_img4 from '../assets/images/weare/foto-guadalajara-tienda.png'
    import section4_img5 from '../assets/images/weare/foto-tlajomulco.png'

    import logo_1970 from '../assets/images/weare/ages/logo-1970.png'
    import logo_1993 from '../assets/images/weare/ages/logo-1993.png'
    import logo_2010 from '../assets/images/weare/ages/logo-2010.png'
    import logo_2012 from '../assets/images/weare/ages/logo-2012.png'
    import logo_2018 from '../assets/images/weare/ages/logo-2018.png'
    import logo_2021 from '../assets/images/weare/ages/logo-premier-colores.png'

    import section5_img1 from '../assets/images/weare/foto-fachada-premier.png'
    import section5_img2 from '../assets/images/weare/foto-tienda-mercado-premier.png'
    import section5_img3 from '../assets/images/weare/foto-tienda-premier-nuts.png'
    import section5_img1_5 from '../assets/images/weare/customers/logo-premier-blanco.png'
    import section5_img2_5 from '../assets/images/weare/customers/logo-mercado-premier-blanco.png'
    import section5_img3_5 from '../assets/images/weare/customers/logo-premier-nuts.png'
    import img_nuts from '../assets/images/footer/premier-nuts.png'
    import img_mercado from '../assets/images/footer/mercado-premier.png'

    import img_customer1 from '../assets/images/weare/customers/logo-heb.png'
    import img_customer2 from '../assets/images/weare/customers/logo-fresko.png'
    import img_customer3 from '../assets/images/weare/customers/logo-soriana.png'
    import img_customer4 from '../assets/images/weare/customers/logo-sams-club.png'
    import img_customer5 from '../assets/images/weare/customers/logo-chedraui.png'
    import img_customer6 from '../assets/images/weare/customers/logo-liverpool.png'

    // Gifs
    import gif_premier_mixed from '../assets/images/home/logo-premier.png'
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_weare',
		data() {
			return {
                video_main,
                img_logo_white,
                section1_img1,
                section1_img2,
                section1_img3,
                section1_img4,

                section2_img1,
                section2_img2,
                section2_img3,
                img_logo,

                s3_video1,
                section3_img0,
                section3_img1,
                section3_img2,
                section3_img3,
                section3_img4,

                img_0,
                section4_img1,
                section4_img2,
                section4_img3,
                section4_img4,
                section4_img5,
                section4_img5,

                logo_1970,
                logo_1993,
                logo_2010,
                logo_2012,
                logo_2018,
                logo_2021,

                section5_img1,
                section5_img2,
                section5_img3,
                section5_img1_5,
                section5_img2_5,
                section5_img3_5,
                img_nuts, 
                img_mercado, 

                img_customer1,
                img_customer2,
                img_customer3,
                img_customer4,
                img_customer5,
                img_customer6,

                gif_premier_mixed,

                current_index_age: 1
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            },
            changeAge(new_age_index) {
                
                //$(`#col_age_${this.current_index_age}`).removeClass('col-xl-2');
                $(`#col_age_${this.current_index_age}`).removeClass('col-md-2');
                //$(`#col_age_${this.current_index_age}`).addClass('col-xl-1');
                $(`#col_age_${this.current_index_age}`).addClass('col-md-1');

                //$(`#col_age_${new_age_index}`).removeClass('col-xl-1');
                $(`#col_age_${new_age_index}`).removeClass('col-md-1');
                //$(`#col_age_${new_age_index}`).addClass('col-xl-2');
                $(`#col_age_${new_age_index}`).addClass('col-md-2');


                $(`#btn_age_${this.current_index_age}`).removeClass('pr--title');
                $(`#btn_age_${this.current_index_age}`).removeClass('text-white');
                $(`#btn_age_${this.current_index_age}`).addClass('pr--description');
                $(`#btn_age_${this.current_index_age}`).addClass('text-muted');

                $(`#btn_age_${new_age_index}`).removeClass('pr--description');
                $(`#btn_age_${new_age_index}`).removeClass('text-muted');
                $(`#btn_age_${new_age_index}`).addClass('pr--title');
                $(`#btn_age_${new_age_index}`).addClass('text-white');

                this.current_index_age = new_age_index
            }
        },
        created() {
            $(window).resize(function() {
                let w = 0
                if($('#pr_video_main').width() > 888) {
                    w = ($('#pr_video_main').width() / 10) * 4
                }
                else {
                    w = ($('#pr_video_main').width() / 10) * 5
                }
                let height_navbar = $('#pr_line_primary').height() + $('#pr_navbar').height() + 30
                
                $("#overlay_main_video").css("width", w)
                let position_left = (($('#pr_video_main').height() / 12) * 2)
                $("#overlay_main_video").css("left", position_left)
                let position_top = height_navbar + (($('#pr_video_main').height() / 2) - ($("#overlay_main_video").height() / 2))
                $("#overlay_main_video").css("top", position_top)

                // Same height
                $("#div_img_logo").css("height", $('#div_img_logo').width())

                
                let height_ref = $('#pr_video_main').height()
                $("#pr_text_main").css("height", height_ref)
            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 2000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_weare {
		.img0-s3 {
            margin-top: 0px;
        }

        #div_img_logo {
            background-color: white;
            max-width: 300px;
            margin-top: -60px;
        }

        // Large devices (desktops, 992px and up)
        @media (min-width: 911px) {
            .img0-s3 {
                margin-top: -200px;
            }
        }

        @media (min-width: 992px) {
            #div_img_logo {
                margin-left: -160px;
                margin-top: 3rem !important;
                margin-bottom: 3rem !important;

            }
        }
	}
</style>