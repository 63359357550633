<template>
  	<div id="pr_category_nuts" class="container-fluid">
  		<!-- image main -->
        <section class="row">
            <div class="col-7 col-md-6 col-lg-5 col-xl-6 d-flex justify-content-center align-items-center text-left p-0 pl-lg-5 py-3">
                <div class="p-0 pl-lg-5" style="max-width: 500px;">
                    <div class="mb-3 px-2">
                        <span class="pr--title">
                            FRUTAS DESHIDRATADAS
                        </span>    
                    </div>
                    <div class="mb-5 d-none d-md-block px-2">
                        <span class="pr--description">
                            Nuestra gran variedad de frutos deshidratados ofrecen calidad y sabor en cada una de sus presentaciones, desde arándanos, cerezas, ciruelas, duraznos, entre otros
                        </span>    
                    </div>
                    <div class="mb-3 d-none d-lg-block">
                        <span class="pr--description poppins-black">
                            BENEFICIOS
                        </span>    
                    </div>
                    <div class="row d-none d-lg-flex">
                        <div class="col-3">
                            <img :src="s1_gif1" class="img-fluid">
                        </div>
                        <div class="col-3">
                            <img :src="s1_gif2" class="img-fluid">
                        </div>
                        <div class="col-3">
                            <img :src="s1_gif3" class="img-fluid">
                        </div>
                        <div class="col-3">
                            <img :src="s1_gif4" class="img-fluid">
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="col-5 col-md-6 col-lg-7 col-xl-6 d-flex justify-content-end px-0">
                <img :src="img_main" class="img-fluid">
            </div>
        </section>

        <!-- image section -->
        <section class="row">
            <div class="col-12 d-flex justify-content-center align-items-center p-0">
                <img :src="section1_img1" class="img-fluid">

                <div class="" style="width: 100%; height: 100%;position: absolute;">
                    <div class="row d-flex justify-content-center align-items-center" style="height: 100%;">
                        <div class="col-12 col-lg-12 p-0 pl-lg-5 py-2">
                            <span class="pr--description text-white poppins-bold d-none d-lg-block">ÚSALOS EN</span>

                            <div class="row d-flex justify-content-center align-items-center py-1 py-lg-4">
                                <div class="col-3 col-md-3 col-lg-2 px-4 px-lg-4 px-xl-5">
                                    <img :src="s2_gif1" class="img-fluid">
                                </div>
                                <div class="col-3 col-md-3 col-lg-2 px-4 px-lg-4 px-xl-5">
                                    <img :src="s2_gif2" class="img-fluid">
                                </div>
                                <div class="col-3 col-md-3 col-lg-2 px-4 px-lg-4 px-xl-5">
                                    <img :src="s2_gif3" class="img-fluid">
                                </div>
                                <div class="col-3 col-md-3 col-lg-2 px-4 px-lg-4 px-xl-5">
                                    <img :src="s2_gif4" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="row d-flex justify-content-center align-items-end py-5">
            <div class="col-12 mb-4">
                <span class="pr--description text-primary poppins-black">PRODUCTOS</span>
            </div>

            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img1" class="img-fluid d-block">
                </div>
                
                <span class="pr--description text-primary">ARÁNDANO</span> <br>
                <span @click="redirect('product_cranberry')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img2" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">CEREZA</span> <br>
                <span @click="redirect('product_cherry')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img3" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">CIRUELA CON HUESO</span> <br>
                <span @click="redirect('product_plum')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img4" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">DURAZNO</span> <br>
                <span @click="redirect('product_peach')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>


            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img4_1" class="img-fluid d-block">
                </div>
                
                <span class="pr--description text-primary">CHABACANO</span> <br>
                <span @click="redirect('product_apricot')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img5" class="img-fluid d-block">
                </div>
                
                <span class="pr--description text-primary">COCO</span> <br>
                <span @click="redirect('product_coconut')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img6" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">FRUTA MIXTA</span> <br>
                <span @click="redirect('product_fruit')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img7" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">MANGO</span> <br>
                <span @click="redirect('product_mango')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img8" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">KIWI</span> <br>
                <span @click="redirect('product_kiwi')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>


            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img9" class="img-fluid d-block">
                </div>
                
                <span class="pr--description text-primary">MANZANA</span> <br>
                <span @click="redirect('product_apple')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img10" class="img-fluid d-block">
                </div>
                <span class="pr--description text-primary">PIÑA</span> <br>
                <span @click="redirect('product_pineapple')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img11" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">UVA PASA CHILENA</span> <br>
                <span @click="redirect('product_raisin')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>


            <div class="col-12 col-sm-6 col-lg-3 my-4">
                <div class="d-flex justify-content-center">
                    <img :src="section3_img12" class="img-fluid d-block">
                </div>

                <span class="pr--description text-primary">DÁTIL</span> <br>
                <span @click="redirect('product_datil')" class="pr--description-sm text-success cursor-pointer">Conoce más ></span>
            </div>
        </section>

        <!--section button-->
        <section class="py-5 p-2">
            <b-button @click="redirect('products')" variant="primary" class="pr--description text-white poppins-bold p-3">
                REGRESAR A PRODUCTOS
            </b-button>
        </section>  
  	</div>
</template>

<script>
    // Gifs
    import s1_gif1 from '../../assets/gif/categories/100-natural.gif'
    import s1_gif2 from '../../assets/gif/categories/antioxidantes.gif'
    import s1_gif3 from '../../assets/gif/categories/libre-colesterol.gif'
    import s1_gif4 from '../../assets/gif/categories/fibra.gif'

    import s2_gif1 from '../../assets/gif/categories/postres-white.gif'
    import s2_gif2 from '../../assets/gif/categories/snacks-white.gif'
    import s2_gif3 from '../../assets/gif/categories/platillos-white.gif'
    import s2_gif4 from '../../assets/gif/categories/bebidas-white.gif'

	// Images
    import img_main from '../../assets/images/categories/dehydrated/foto-fruta-deshidratada.png'
    import section1_img1 from '../../assets/images/categories/dehydrated/foto-franja-arandano.png'
        
    import section3_img1 from '../../assets/images/categories/dehydrated/foto-arandano.png'
    import section3_img2 from '../../assets/images/categories/dehydrated/foto-cereza.png'
    import section3_img3 from '../../assets/images/categories/dehydrated/foto-ciruela-hueso.png'
    import section3_img4 from '../../assets/images/categories/dehydrated/foto-durazno.png'

    import section3_img4_1 from '../../assets/images/categories/dehydrated/foto-chabacano.png'
    import section3_img5 from '../../assets/images/categories/dehydrated/04-coco-premier.png'
    import section3_img6 from '../../assets/images/categories/dehydrated/06-fruta-mixta.png'
    import section3_img7 from '../../assets/images/categories/dehydrated/08-mango-deshidratado.png'

    import section3_img8 from '../../assets/images/categories/dehydrated/07-kiwi-premier.png'
    import section3_img9 from '../../assets/images/categories/dehydrated/09-manzana-rodajas.png'
    import section3_img10 from '../../assets/images/categories/dehydrated/11-piña-deshidratada.png'
    import section3_img11 from '../../assets/images/categories/dehydrated/12-uva-pasa.png'

    import section3_img12 from '../../assets/images/categories/dehydrated/13-datil.png'
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_category_nuts',
		data() {
			return {
                img_main,
                s1_gif1,
                s1_gif2,
                s1_gif3,
                s1_gif4,

                section1_img1,

                s2_gif1,
                s2_gif2,
                s2_gif3,
                s2_gif4,

                section3_img1,
                section3_img2,
                section3_img3,
                section3_img4,
                section3_img4_1, 
                section3_img5,
                section3_img6,
                section3_img7,
                section3_img8,
                section3_img9,
                section3_img10,
                section3_img11,
                section3_img12
			}
	    },
        methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        }
	}
</script>

<style scoped lang="scss">
	#pr_category_nuts {
		
	}
</style>