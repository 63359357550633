<template>
    <div id="pr_product_walnut" class="container-fluid">
        <!-- image main -->
        <section class="row">
            <div class="col-12 d-flex justify-content-center align-items-center p-0">
                <img :src="section1_img1" class="img-fluid"/>

                <div class="row" style="position: absolute;">
                    <div class="col-12">
                        <img :src="img_logo" class="img-fluid px-5 px-sm-0"/>
                    </div>
                    <div class="col-12 d-none d-sm-block mt-5">
                        <h1 class="pr--title poppins-light text-white">D U R A Z N O &nbsp D E S H I D R A T A D O</h1>
                    </div>
                </div>
            </div>
        </section>

        <!--section informative -->
        <section class="row d-flex justify-content-center align-items-center">
            <div style="max-width: 1240px">
                <section class="row">
                    <div class="col-md-12 col-lg-6 d-flex justify-content-center text-left py-5">
                        <div style="max-width: 600px;">
                            <span class="pr--title poppins-bold">DURAZNO DESHIDRATADO</span>
                            
                            <div style="max-width: 600px;">
                                <p class="text-left pr--description pt-5">
                                    <span class="poppins-bold"> El durazno deshidratado Premier</span> contiene un elevado aporte de fibra insoluble promoviendo la reducción del colesterol en la sangre. <br><br>

                                    </span>Ideal para 
                                    <span class="poppins-bold">snacks, tizanas, barritas energéticas, granola y postres.</span>
                                </p>  
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 p-0">
                        <img :src="section2_img1" class="img-fluid">
                    </div>
                </section>
            </div>
        </section>
        
        <!-- version 2 -->
        <section class="row d-flex justify-content-center align-items-center">
            <div style="max-width: 1240px">
                <div class="row">

                    <div class="col-md-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center justify-content-lg-end align-items-center px-5">
                        <img :src="section3_img1" class="img-fluid pr-0 pr-lg-5 ">
                    </div>

                    <div class="col-md-12 col-lg-6 order-0 order-lg-1 text-left px-4 px-md-5 py-5">
                        
                        <div class="row d-flex justify-content-start align-items-center">
                            <div class="col-12 bg-success pl-4 py-2">
                                <span class="pr--description text-white poppins-bold">PROPIEDADES</span>
                            </div>
                            <div class="col-12 mb-5" style="background-color: #f1f1f1">
                                <p class="pr--description poppins-light py-3 py-lg-4 m-0" style="color: #2d2d2dc7">
                                    <ul>
                                        <li class="my-2">Rico en Vitamina A</li>
                                        <li class="my-2">Alto en Fibra Dietética</li>
                                        <li class="my-2">Promueve la salud digestiva</li>
                                        <li class="my-2">100% Natural</li>
                                    </ul>
                                </p> 
                            </div>

                            <div class="col-12 bg-success pl-4 py-2">
                                <span class="pr--description text-white poppins-bold">País de Origen:</span>
                            </div>
                            <div class="col-12 mb-5" style="background-color: #f1f1f1">
                                <p class="pr--description poppins-light py-3 py-lg-4 pl-3 m-0" style="color: #2d2d2dc7">Tailandia</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>

        <!--section button-->
        <section class="py-5 p-2">
            <b-button @click="redirect('category_dehydrated')" variant="primary" class="pr--description text-white poppins-bold p-3">
                REGRESAR A FRUTAS DESHIDRATADAS
            </b-button>
        </section>
    </div>
</template>

<script>
    // Images
    import img_logo from '../../assets/images/products/logo.png'
    import section1_img1 from '../../assets/images/products/peach/foto-01-durazno.jpg'
    import section2_img1 from '../../assets/images/products/peach/foto-02-durazno.png'
    import section3_img1 from '../../assets/images/products/peach/foto-03-durazno.png'
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_product_peach',
		data() {
			return {
                img_logo,
                section1_img1,
                section2_img1,
                section3_img1
			}
	    },
        methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        }
	}
</script>

<style scoped lang="scss">
	#pr_product_walnut {
		
	}
</style>