<template>
  	<div id="pr_ocean" class="container-fluid">
  		<!-- image main -->
		<section id="pr_video_main" class="section__index d-flex">
            <img :src="img_main" class="img-fluid">

            <div id="pr_text_main" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 d-flex justify-content-center align-items-end p-0 pl-lg-5" style="height: 100%;">
                        <div class="p-0 pb-3 pb-lg-5" style="max-width: 500px;">
                            <div class="d-none d-sm-flex justify-content-center">
                                <img :src="section1_img1" class="img-fluid">    
                            </div>
                            <div class="d-flex justify-content-center">
                                <span class="pr--description d-none d-md-flex text-white">Somos distribuidores exclusivos de</span>
                            </div>
                            <div>
                                <span class="pr--title-lg poppins-bold text-white">OCEAN SPRAY</span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- line #752c2c -->
        <section class="row d-flex justify-content-center align-items-center py-5 px-0" style="background-color: #752c2c">
            <div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <img :src="img_logo_white" class="img-fluid mb-4 mb-xl-5">        
                    </div>
                    <div class="col-12 col-lg-6">
                        <img :src="img_logo_ocean" class="img-fluid mb-4 mb-xl-5">
                    </div>
                </div>
                

                <div class="px-5" style="max-width: 800px;">
                    <span class="pr--description text-white">
                        Distribuidores Exclusivos de Arándanos Deshidratados OCEAN SPRAY a Nivel Nacional.
                    </span>
                </div>
            </div>
        </section>

        <!-- image-text section -->
        <section class="row p-0 py-4">
            <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <img :src="section2_img1" class="img-fluid">
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-center">
                <div class="pt-5 px-0 pl-lg-5 text-left">
                    <div class="mb-2" style="max-width: 700px;">
                        <span class="pr--title text-left">
                            EL ARÁNDANO <br>
                            <span class="text-success">UN FRUTO EXCEPCIONAL</span>
                        </span>    
                    </div>
                    <div class="pb-5" style="max-width: 550px;">
                        <span class="pr--description">
                            Desde el año 2010 somos el primer contacto de Ocean Spray para la comercialización de arándanos deshidratados y sus variantes en México. <br><br>
                            Trabajamos de la mano para proveer información de su línea de productos y muestras de innovaciones.   
                        </span>    
                    </div>
                </div>
            </div>
        </section>

        <!-- img_play video -->
        <section class="section__index p-0">
            <div class="px-0 px-lg-3 px-xl-5">
                <div class="px-0 px-lg-3 px-xl-5">
                    <div id="image_real" class="d-flex justify-content-center align-items-center" style="background-size: cover;" :style="{ backgroundImage: 'url(' + img_video + ')' }">
                       <img @click="openModal('https://player.vimeo.com/video/646581007')" :src="img_play" class="pr-img-play">
                    </div>

                    <img id="image_ref" :src="img_video" class="img-fluid" style="position: absolute; top: -10000px;">
                </div>
            </div>
        </section>

        <!-- line green -->
        <section class="row bg-success d-flex justify-content-center align-items-center px-0 py-5 mt-5">

            <div class="col-12 d-flex justify-content-center align-items-center text-center line-height-120 p-3 p-xl-5">
                <div style="max-width: 1200px;">
                    <span class="pr--title text-white">PRODUCTOS OCEAN SPRAY IMPORTADOS POR GRUPO PREMIER:</span>
                </div>    
            </div>
            

            <div class="col-12 col-md-6 col-lg-5 my-3 p-3 p-xl-5 text-left d-flex justify-content-center justify-content-md-end">
                <div class="p-3 p-xl-5" style="max-width: 800px;">
                    <ul class="pr--description text-white">
                        <li class="my-3"> Arándano Classic Whole </li>
                        <li class="my-3"> Arándano Double Dice </li>
                        <li class="my-3"> Arándano Reduced Sugar </li>
                        <li class="my-3"> Arándano Soft & Moist </li>
                        <li class="my-3"> Arándano Sabor Blueberry </li>
                        <li class="my-3"> Arándano Sabor Cherry </li>
                        <li class="my-3"> Arándano Sabor Fresa </li>
                        <li class="my-3"> Arándano Sabor Naranja </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-7 d-flex justify-content-center my-3">
                <div class="row d-flex justify-content-center align-items-center w-100">
                    <div class="col-12 col-md-6 d-flex justify-content-center justify-content-lg-start">
                        <img :src="section3_img1" class="img-fluid">
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-center justify-content-lg-start pt-4 pt-md-0">
                        <img :src="section3_img2" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>

        <!-- play video  -->
        <section id="pr_video_main_3" class="section__index d-flex" v-if="false">
            <img :src="section4_img1" class="img-fluid">

            <div id="pr_text_main_3" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 d-flex justify-content-center align-items-end p-0 pl-lg-5" style="height: 100%;">
                        <div class="p-0 pb-3 pb-lg-5" style="max-width: 800px;">
                            <div>
                                <span class="pr--title text-white">
                                    SÓLIDA RELACIÓN 
                                </span>    
                            </div>
                            <div class="d-none d-md-flex mt-3">
                                <span class="pr--description text-white">
                                    Cada día fortalecemos nuestra relación con OCEAN SPRAY, asegurando que los productos entregados a nuestro consumidor final tengan los estándares de calidad necesarios para satisfacer sus necesidades.
                                </span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- Modal -->
        <div id="myModal" class="modal px-1 px-md-5" style="display: none; justify-content: center; align-items: center; background-color: #000000bb;" @click="closeModal()">
            <!-- Modal content -->
            <div class="modal-custom px-1 px-md-5">
                <span @click="closeModal()" class="close cursor-pointer text-white">&times;</span>
                
                <iframe id="video" :src="src_video" 
                    class="responsive-iframe" 
                    allowscriptaccess="always" allow="autoplay" v-if="play_in">
                </iframe>
            </div>
        </div>
  	</div>
</template>

<script>
	// Video
    
    // Images
    import img_main from '../assets/images/ocean/foto-slide.png'
    import section1_img1 from '../assets/images/ocean/logo-ocean-spray-color.png'
    import img_logo_white from '../assets/images/ocean/logo-premier-blanco.png'
    import img_logo_ocean from '../assets/images/ocean/logo-ocean-spray-negro.png'

    import section2_img1 from '../assets/images/ocean/foto-arandano-plato.png'

    import img_video from '../assets/images/ocean/foto-pantalla-video.png'
    import img_play from '../assets/images/ocean/icono-play.png'   
    
    import section3_img1 from '../assets/images/ocean/ocean-caja.png'
    import section3_img2 from '../assets/images/ocean/ocean-bolsa.png'

    import section4_img1 from '../assets/images/ocean/foto-solida-relacion.png'
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_ocean',
		data() {
			return {
                img_main,
                img_video,
                img_play,

                section1_img1,
                img_logo_white,
                img_logo_ocean,

                section2_img1,

                section3_img1,
                section3_img2,

                section4_img1,

                // Modal
                src_video: null,
                play_in: false,
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            },
            openModal(url) {
                var modal = document.getElementById("myModal");
                modal.style.display = "flex";
                this.play_in = true

                this.src_video = url + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
            },
            closeModal() {
                var modal = document.getElementById("myModal");
                modal.style.display = "none";
                this.play_in = false
            }
        },
        created() {
            $(window).resize(function() {
                // Image main
                let height_ref = $('#pr_video_main').height()
                $("#pr_text_main").css("height", height_ref)

                // Image play
                let height_ref1 = $('#image_ref').height()
                $("#image_real").css("height", height_ref1)

                // Image text down
                let height_ref3 = $('#pr_video_main_3').height()
                $("#pr_text_main_3").css("height", height_ref3)
            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 2000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_ocean {
		
	}
</style>