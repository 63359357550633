import { render, staticRenderFns } from "./CategoryJamaica.vue?vue&type=template&id=73ac4561&scoped=true&"
import script from "./CategoryJamaica.vue?vue&type=script&lang=js&"
export * from "./CategoryJamaica.vue?vue&type=script&lang=js&"
import style0 from "./CategoryJamaica.vue?vue&type=style&index=0&id=73ac4561&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73ac4561",
  null
  
)

export default component.exports