<template>
  	<div id="pr_commitment" class="container-fluid">
  		<!-- video main -->
        <section id="pr_video_main" class="section__index d-flex">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="video_main" type="video/mp4">
            </video>

            <div id="pr_text_main" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 mt-5"></div>
                    <div id="our-commitment" class="col-12 d-flex justify-content-start align-items-center p-0 pl-lg-5" style="height: 100%;">
                        <div data-aos="fade-up" class="text-left p-0 pl-lg-5 pb-3 pb-lg-5" style="max-width: 700px;">
                            <span class="pr--title-lg poppins-bold text-white text-left">
                                NUESTRO COMPROMISO
                            </span> <br>
                            <span class="pr--description text-white">
                                Hacia el desarrollo del personal y nuestros socios.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- line green -->
        <section class="row bg-success d-flex justify-content-center align-items-center py-5">
            <div>
                <img :src="img_logo_white" data-aos="fade-up" class="img-fluid mb-4 mb-xl-5"> <br>

                <div data-aos="fade-up" data-aos-delay="400" class="px-5" style="max-width: 800px;">
                    <span class="pr--description text-white">
                        Estamos comprometidos con el desarrollo de nuestro capital humano, pilar fundamental de grupo Premier.
                    </span>
                </div>
            </div>
        </section>

        <!-- cols informatives -->
        <section class="row d-flex justify-content-center align-items-start py-5">
            <div class="col-12 col-md-6 col-lg-4 px-5 px-lg-5 mb-4">
                <img :src="section1_img1" data-aos="fade-up" class="img-fluid">

                <div class="d-flex justify-content-center text-left pt-3 px-1 px-lg-1">
                    <div style="max-width: 510px;">
                        <div data-aos="fade-up" data-aos-delay="400">
                            <span class="pr--description poppins-bold">DESARROLLO HUMANO</span>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="600" class="pt-3">
                            <span class="pr--description">
                                En Grupo Premier estamos convencidos que el desarrollo humano es parte fundamental de toda la 
                                empresa; por ello nos esforzamos día a día para ofrecer un desarrollo personal y profesional a 
                                cada uno de nuestros trabajadores.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-5 px-lg-5 mb-4">
                <img :src="section1_img2" data-aos="fade-up" class="img-fluid">

                <div class="d-flex justify-content-center text-left pt-3 px-1 px-lg-3">
                    <div style="max-width: 510px;">
                        <div data-aos="fade-up" data-aos-delay="400">
                            <span class="pr--description poppins-bold">RELACIÓN CON SOCIOS</span>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="600" class="pt-3">
                            <span class="pr--description">
                                En GRUPO PREMIER nos preocupamos día a día por consolidar una fuerte relación con cada uno de 
                                nuestros socios alrededor del mundo, creando así nuestra gran Familia Premier.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- image section -->
        <section class="row">
        	<div class="col-12 d-flex justify-content-center align-items-center p-0">
                <img :src="section1_img3" class="img-fluid">

                <div style="width: 100%; position: absolute;">
                    <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6 d-flex justify-content-center">
                            <div class="pl-5" style="max-width: 555px;">
                                <img :src="img_logo" data-aos="fade-up" class="img-fluid mb-4">
                                <span data-aos="fade-up" data-aos-delay="400" class="pr--title text-white text-left d-none d-md-flex">
                                    EL DESARROLLO HUMANO ES UNO DE NUESTROS PILARES FUNDAMENTALES
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
        	</div>
        </section>

        <!-- col image informative -->
        <section class="row">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center p-2">
                <img :src="section2_img1" data-aos="zoom-in-down" class="img-fluid">
        	</div>
        	<div class="col-12 col-lg-6 pb-5">
        		<div class="row text-left px-0 px-md-5">
        			<div data-aos="fade-up" class="col-12 pt-5">
        				<div style="max-width: 600px;">
		        			<span class="pr--title poppins-bold">COMPROMISO</span>
		        		</div>
        			</div>
        			<div class="col-12 d-flex justify-content-start align-items-center text-left pt-3 pt-xl-5">
                        <div>
                            <div data-aos="fade-up" data-aos-delay="400" style="max-width: 600px;">
                                <span data-aos="fade-up" data-aos-delay="400" class="pr--description">
                                    En Grupo Premier nos preocupamos por el desarrollo del nuestro personal en todos los niveles, por medio de capacitaciones constantes y herramientas que faciliten su desarrollo profesional y laboral a través de los siguientes compromisos:
                                </span>
                            </div><br>
                            <div data-aos="fade-up" data-aos-delay="500" style="max-width: 600px;">
                                <ul class="pr--description">
                                    <li class="my-3">COMPROMISO CON LA EXCELENCIA</li>
                                    <li class="my-3">COMPROMISO CON EL APRENDIZAJE</li>
                                    <li class="my-3">COMPROMISO CON LAS SOLUCIONES</li>
                                    <li class="my-3">COMPROMISO CON LOS DEMÁS</li>
                                    <li class="my-3">COMPROMISO CON LA PERSEVERANCIA</li>
                                </ul>
                            </div>
                        </div>
        			</div>
        		</div>
        	</div>
        </section>

        <!-- col hover images -->
        <section class="row d-flex justify-content-start align-items-start">
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center align-items-center p-0">
                <img :src="section3_img1" class="img_black_white img-fluid p-0" style="margin-right: -4px">
            </div>
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center align-items-center p-0">
                <img :src="section3_img2" class="img_black_white img-fluid p-0" style="margin-right: -2px">
            </div>
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center align-items-center p-0">
                <img :src="section3_img3" class="img_black_white img-fluid p-0" style="margin-left: -2px">
            </div>
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center align-items-center p-0">
                <img :src="section3_img4" class="img_black_white img-fluid p-0" style="margin-left: -3px">
            </div>
        </section>

        <!-- line green -->
        <section class="row bg-success d-flex justify-content-center align-items-center py-5">
            <div>
                <img :src="img_logo_white" data-aos="fade-up" class="img-fluid mb-4 mb-xl-5"> <br>

                <div data-aos="fade-up" data-aos-delay="400" class="px-5" style="max-width: 800px;">
                    <span class="pr--description text-white">
                        En los últimos 50 años Grupo Premier nos hemos consolidado como la empresa líder en importación, procesamiento y distribución de frutos secos en México.
                    </span>
                </div>
            </div>
        </section>

        <!-- img main -->
        <section id="our-family" class="section__index d-flex">
            <img id="pr_video_main_2" :src="section4_img1" class="img-fluid">

            <div id="pr_text_main_2" class="w-100 position-absolute">
                <div id="pr_row_text_main_2" class="row d-flex justify-content-center align-items-end">
                    <div data-aos="fade-up" class="col-12 d-flex justify-content-center align-items-end p-0 pl-lg-5 pb-5">
                        <div class="p-0 pl-lg-5 pb-3 pb-lg-5" style="max-width: 600px;">
                            <span class="pr--title text-white text-left">
                                NUESTRA GRAN FAMILIA DE SOCIOS
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- col image informative -->
        <section class="row py-5" v-if="false">
            <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center p-2">
                <img :src="section4_img2" class="img-fluid">
            </div>
            <div class="col-12 col-lg-6 pb-5">
                <div class="row text-left px-0 px-md-5">
                    <div class="col-12 pt-5">
                        <div class="pt-5" style="max-width: 500px;">
                            <span class="pr--title poppins-bold">SÓLIDA RELACIÓN CON NUESTROS SOCIOS</span>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center text-left pt-3 pt-xl-5">
                        <div style="max-width: 550px;">
                            <span class="pr--description">
                                En GRUPO PREMIER nos preocupamos día a día por consolidar una fuerte relación con cada 
                                uno de nuestros socios alrededor del mundo, creando así nuestra gran Familia Premier.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- col image informative -->
        <section class="row d-flex justify-content-center align-items-center py-5">
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                    <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0" 
                        :data-aos="(((img_index + 1) % 2) == 0) ? 'fade-up-left' : 'fade-up-right'"
                        :class="(((img_index + 1) % 2) == 0) ? 'justify-content-lg-start' : 'justify-content-lg-end'" 
                        v-for="(img, img_index) in img_cols" :key="img_index">
                        <img :src="img" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
  	</div>
</template>

<script>
	// Video
    import video_main from '../assets/video/commitment/loop-compromiso.mp4'
    
    // Images
    import img_logo_white from '../assets/images/home/logo-premier-blanco.png'
    import section1_img1 from '../assets/images/commitment/foto-desarrollo-humano.png'
    import section1_img2 from '../assets/images/commitment/foto-relacion-con-socios.png'
    import section1_img3 from '../assets/images/commitment/foto-desarrollo-humano-pilar.png'

    import section2_img1 from '../assets/images/commitment/foto-compromiso.png'
    import img_logo from '../assets/images/home/logo-premier.png'

    import section3_img1 from '../assets/images/commitment/foto-c01-hover.png'
    import section3_img2 from '../assets/images/commitment/foto-c02-hover.png'
    import section3_img3 from '../assets/images/commitment/foto-c03-hover.png'
    import section3_img4 from '../assets/images/commitment/foto-c04-hover.png'

    import section4_img1 from '../assets/images/commitment/foto-nuestra-gran-familia-socios.jpg'
    import section4_img2 from '../assets/images/commitment/foto-solida-relacion-socios.jpg'

    import section5_img1 from '../assets/images/commitment/img_cols/foto-01.png'
    import section5_img2 from '../assets/images/commitment/img_cols/foto-02.png'
    import section5_img3 from '../assets/images/commitment/img_cols/foto-03.png'
    import section5_img4 from '../assets/images/commitment/img_cols/foto-04.jpg'
    import section5_img5 from '../assets/images/commitment/img_cols/foto-05.png'
    import section5_img6 from '../assets/images/commitment/img_cols/foto-06.png'
    import section5_img7 from '../assets/images/commitment/img_cols/foto-07.png'
    import section5_img8 from '../assets/images/commitment/img_cols/foto-08.jpg'
    import section5_img9 from '../assets/images/commitment/img_cols/foto-09.png'
    import section5_img10 from '../assets/images/commitment/img_cols/foto-10.png'
    import section5_img11 from '../assets/images/commitment/img_cols/foto-11.png'
    import section5_img12 from '../assets/images/commitment/img_cols/foto-12.png'

    
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_commitment',
		data() {
			return {
                video_main,
                img_logo_white,
                section1_img1,
                section1_img2,
                section1_img3,

                section2_img1,  
                img_logo,

                section3_img1,
                section3_img2,
                section3_img3,
                section3_img4,

                section4_img1,
                section4_img2,

                img_cols: [
                    section5_img1,
                    section5_img2,
                    section5_img3,
                    section5_img4,
                    section5_img5,
                    section5_img6,
                    section5_img7,
                    section5_img8,
                    section5_img9,
                    section5_img10,
                    section5_img11,
                    section5_img12    
                ]
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
        created() {
            $(window).resize(function() {
                // Image main
                let height_ref = $('#pr_video_main').height()
                $("#pr_text_main").css("height", height_ref)

                // Image center
                let height_ref2 = $('#pr_video_main_2').height()
                $("#pr_text_main_2").css("height", height_ref)
                $("#pr_row_text_main_2").css("height", height_ref)

            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 2000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_commitment {
		.img_black_white {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
        }

        .img_black_white:hover {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
        }
	}
</style>