<template>
  	<div id="pr_infrastructure" class="container-fluid">
  		<!-- img main -->
        <section id="pr_video_main" class="section__index d-flex">
            <img :src="img_main" class="img-fluid">

            <div id="pr_text_main" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 d-flex justify-content-center align-items-end p-0 pl-lg-5" style="height: 100%;">
                        <div class="p-0 pl-lg-5 pb-3 pb-lg-5" style="max-width: 500px;">
                            <span class="pr--title text-white text-left">
                                NUESTRA INFRAESTRUCTURA
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- section group 1 -->
        <section id="pr_group1_img" class="section__index d-flex">
            <img :src="group1_img_main" class="img-fluid">

            <div id="pr_group1_cont" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 col-lg-6"></div>

                    <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-5 img-contain-1" style="height: 100%;">

                        <div class="text-left square-informative p-3 p-sm-5">
                            <span class="pr--title text-white text-left">
                                GUADALAJARA
                            </span> <br>
                            <span class="pr--description poppins-bold text-white text-left">
                                OFICINAS CENTRALES
                            </span> <br><br>
                            <span class="pr--description text-white text-left">
                                Paseo del Pacífico 645 Carretera Guadalajara – Nogales km 13.5 Technology Park Guadalajara Zapopan, Jalisco, México. C.P. 45222 Teléfono: + 52 (33) 8000 8300
                            </span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <section class="row d-flex justify-content-center align-items-center group-img-1 mb-5"> 
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group1_img1" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group1_img2" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group1_img3" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group1_img4" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group1_img5" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group1_img6" class="img-fluid">
                    </div>  
                </div>
            </div>
        </section>

        <!-- section group 2 -->
        <section id="pr_group2_img" class="section__index d-flex">
            <img :src="group2_img_main" class="img-fluid">

            <div id="pr_group2_cont" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 col-lg-6"></div>

                    <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-5 img-contain-1" style="height: 100%;">

                        <div class="text-left square-informative p-3 p-sm-5">
                            <span class="pr--title text-white text-left">
                                CIUDAD DE MÉXICO
                            </span> <br>
                            <span class="pr--description poppins-bold text-white text-left">
                                MERCADO DE ABASTOS
                            </span> <br><br>
                            <span class="pr--description text-white text-left">
                                Calle 2 Bodega #17 A Central de Abastos Iztapalapa, D.F. Teléfono: +52 (55) 5600 7192
                            </span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <section class="row d-flex justify-content-center align-items-center group-img-1 mb-5"> 
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group2_img1" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group2_img2" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group2_img3" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group2_img4" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group2_img5" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group2_img6" class="img-fluid">
                    </div>  
                </div>
            </div>
        </section>

        <!-- section group 3 -->
        <section id="pr_group3_img" class="section__index d-flex">
            <img :src="group3_img_main" class="img-fluid">

            <div id="pr_group3_cont" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 col-lg-6"></div>

                    <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-5 img-contain-1" style="height: 100%;">

                        <div class="text-left square-informative p-3 p-sm-5">
                            <span class="pr--title text-white text-left">
                                MONTERREY
                            </span> <br>
                            <span class="pr--description poppins-bold text-white text-left">
                                ALMACÉN
                            </span> <br><br>
                            <span class="pr--description text-white text-left">
                                Av. Ignacio Sepúlveda S/N Parque Industrial Kalos Encarnación, Bodega 140 Apodaca NL C.P. 66630 Celular: 81 1204 2053 E-mail ventasc7@gpopremier.com
                            </span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <section class="row d-flex justify-content-center align-items-center group-img-1 mb-5"> 
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group3_img1" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group3_img2" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group3_img3" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group3_img4" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group3_img5" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group3_img6" class="img-fluid">
                    </div>  
                </div>
            </div>
        </section>

        <!-- section group 4 -->
        <section id="pr_group4_img" class="section__index d-flex">
            <img :src="group4_img_main" class="img-fluid">

            <div id="pr_group4_cont" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">

                    <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-5 img-contain-1" style="height: 100%;">

                        <div class="text-left square-informative p-3 p-sm-5">
                            <span class="pr--title text-white text-left">
                                GUADALAJARA
                            </span> <br>
                            <span class="pr--description poppins-bold text-white text-left">
                                PLANTA PROCESADORA
                            </span> <br><br>
                            <span class="pr--description text-white text-left">
                                Camino Antiguo a San Isidro #910 Col. Nicolás R. Casillas C.P. 45645 Teléfono: 36861438 E-mail: hri@gpopremier.com
                            </span>
                        </div>
                        
                    </div>

                    <div class="col-12 col-lg-6"></div>
                </div>
            </div>
        </section>
        <section class="row d-flex justify-content-center align-items-center group-img-1 mb-5"> 
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group4_img1" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group4_img2" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group4_img3" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group4_img4" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group4_img5" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group4_img6" class="img-fluid">
                    </div>  
                </div>
            </div>
        </section>

        <!-- section group 5 -->
        <section id="pr_group5_img" class="section__index d-flex">
            <img :src="group5_img_main" class="img-fluid">

            <div id="pr_group5_cont" style="width: 100%; position: absolute;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 col-lg-6"></div>

                    <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-5 img-contain-1" style="height: 100%;">

                        <div class="text-left square-informative p-3 p-sm-5">
                            <span class="pr--title text-white text-left">
                                GUADALAJARA
                            </span> <br>
                            <span class="pr--description poppins-bold text-white text-left">
                                PUNTO DE VENTA
                            </span> <br><br>
                            <span class="pr--description text-white text-left">
                                Mercado de Abastos, Calle 7 Bloque G # 701, Mercado de Abastos Guadalajara, Jal. Mex. Teléfono: (33) 8000 8300 E-mail: Irr@gpopremier.com
                            </span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <section class="row d-flex justify-content-center align-items-center group-img-1 mb-5"> 
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group5_img1" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group5_img2" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group5_img3" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group5_img4" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group5_img5" class="img-fluid">
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 px-0">
                        <img :src="group5_img6" class="img-fluid">
                    </div>  
                </div>
            </div>
        </section>
  	</div>
</template>

<script>
    // Images
    import img_main from '../assets/images/infrastructure/foto-slide.png'

    import group1_img_main from '../assets/images/infrastructure/foto-guadalajara-oficinas-centrales.png'
    import group1_img1 from '../assets/images/infrastructure/group_1/foto-gdl-1.png'
    import group1_img2 from '../assets/images/infrastructure/group_1/foto-gdl-2.png'
    import group1_img3 from '../assets/images/infrastructure/group_1/foto-gdl-3.png'
    import group1_img4 from '../assets/images/infrastructure/group_1/foto-gdl-4.png'
    import group1_img5 from '../assets/images/infrastructure/group_1/foto-gdl-5.png'
    import group1_img6 from '../assets/images/infrastructure/group_1/foto-gdl-6.png'

    import group2_img_main from '../assets/images/infrastructure/foto-ciudad-de-mexico-main.png'
    import group2_img1 from '../assets/images/infrastructure/group_2/foto-cdmx-1.png'
    import group2_img2 from '../assets/images/infrastructure/group_2/foto-cdmx-2.png'
    import group2_img3 from '../assets/images/infrastructure/group_2/foto-cdmx-3.png'
    import group2_img4 from '../assets/images/infrastructure/group_2/foto-cdmx-4.png'
    import group2_img5 from '../assets/images/infrastructure/group_2/foto-cdmx-5.png'
    import group2_img6 from '../assets/images/infrastructure/group_2/foto-cdmx-6.png'

    import group3_img_main from '../assets/images/infrastructure/foto-monterrey-main.png'
    import group3_img1 from '../assets/images/infrastructure/group_3/foto-mty-1.png'
    import group3_img2 from '../assets/images/infrastructure/group_3/foto-mty-2.png'
    import group3_img3 from '../assets/images/infrastructure/group_3/foto-mty-3.png'
    import group3_img4 from '../assets/images/infrastructure/group_3/foto-mty-4.png'
    import group3_img5 from '../assets/images/infrastructure/group_3/foto-mty-5.png'
    import group3_img6 from '../assets/images/infrastructure/group_3/foto-mty-6.png'

    import group4_img_main from '../assets/images/infrastructure/foto-guadalajara-planta-main.jpg'
    import group4_img1 from '../assets/images/infrastructure/group_4/foto-gdl-planta-1.png'
    import group4_img2 from '../assets/images/infrastructure/group_4/foto-gdl-planta-2.png'
    import group4_img3 from '../assets/images/infrastructure/group_4/foto-gdl-planta-3.png'
    import group4_img4 from '../assets/images/infrastructure/group_4/foto-gdl-planta-4.png'
    import group4_img5 from '../assets/images/infrastructure/group_4/foto-gdl-planta-5.png'
    import group4_img6 from '../assets/images/infrastructure/group_4/foto-gdl-planta-6.png'

    import group5_img_main from '../assets/images/infrastructure/foto-guadalajara-punto-venta-main.jpg'
    import group5_img1 from '../assets/images/infrastructure/group_5/foto-gdl-venta-1.png'
    import group5_img2 from '../assets/images/infrastructure/group_5/foto-gdl-venta-2.png'
    import group5_img3 from '../assets/images/infrastructure/group_5/foto-gdl-venta-3.png'
    import group5_img4 from '../assets/images/infrastructure/group_5/foto-gdl-venta-4.png'
    import group5_img5 from '../assets/images/infrastructure/group_5/foto-gdl-venta-5.png'
    import group5_img6 from '../assets/images/infrastructure/group_5/foto-gdl-venta-6.png'
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_infrastructure',
		data() {
			return {
                img_main,

                group1_img_main,
                group1_img1,
                group1_img2,
                group1_img3,
                group1_img4,
                group1_img5,
                group1_img6,

                group2_img_main,
                group2_img1,
                group2_img2,
                group2_img3,
                group2_img4,
                group2_img5,
                group2_img6,

                group3_img_main,
                group3_img1,
                group3_img2,
                group3_img3,
                group3_img4,
                group3_img5,
                group3_img6,

                group4_img_main,
                group4_img1,
                group4_img2,
                group4_img3,
                group4_img4,
                group4_img5,
                group4_img6,

                group5_img_main,
                group5_img1,
                group5_img2,
                group5_img3,
                group5_img4,
                group5_img5,
                group5_img6,
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
        created() {
            $(window).resize(function() {
                // Image main
                let height_ref = $('#pr_video_main').height()
                $("#pr_text_main").css("height", height_ref)

                // Image gropu1
                let height_ref2 = $('#pr_group1_img').height()
                $("#pr_group1_cont").css("height", height_ref2)

                // Image gropu2
                let height_ref3 = $('#pr_group2_img').height()
                $("#pr_group2_cont").css("height", height_ref3)

                // Image gropu3
                let height_ref4 = $('#pr_group3_img').height()
                $("#pr_group3_cont").css("height", height_ref4)

                // Image gropu4
                let height_ref5 = $('#pr_group4_img').height()
                $("#pr_group4_cont").css("height", height_ref5)

                // Image gropu5
                let height_ref6 = $('#pr_group5_img').height()
                $("#pr_group5_cont").css("height", height_ref6)
            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 2000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_infrastructure {
        .square-informative {
            background-color: #00255499;
            border-radius: 8px;
        }
        .img-contain-1 {
            padding-bottom: 0px;
        }
		.group-img-1 {
            margin-top: 0px;
        }

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            .img-contain-1 {
                padding-bottom: 200px;
            }
            .group-img-1 {
                margin-top: -200px;
            }
        }
	}
</style>